import { useEffect, useMemo } from "react";
import styles from "@/app/components/ChatBox/BasicEditor.module.scss";
import Document from "@tiptap/extension-document";
import { HardBreak } from "@tiptap/extension-hard-break";
import { History } from "@tiptap/extension-history";
import Mention from "@tiptap/extension-mention";
import Paragraph from "@tiptap/extension-paragraph";
import Placeholder from "@tiptap/extension-placeholder";
import Text from "@tiptap/extension-text";
import { Editor, EditorContent, ReactRenderer, useEditor } from "@tiptap/react";
import { MentionList } from "../MentionList";

import Link from "@tiptap/extension-link";

import { PluginKey } from "prosemirror-state";
import { globalStore, useAppStore } from "@/app/store";
import { DocNode } from "@/app/store/ChatBox.store";
import { useParser } from "@/app/hooks/useParser";
import { getMentions } from "@/app/services/mentions";
import useDebounce from "@/app/hooks/useDebounce";
import { StructUser } from "@/app/types/Thread.type";
import { StructChannel } from "@/app/types/Channel.type";

enum ExtendedPlugins {
  Link = "link",
}

interface BasicEditorProps {
  onChange: (content: string) => void;
  placeholder?: string;
  value?: string | DocNode;
  onEnter?: (editorOutput: string) => void;
  extendedPlugins?: ExtendedPlugins[];
  canCreateTags?: boolean;
  includedMentions?: string[];
}

const extractTextWithNewlines = (state: Editor["state"]) => {
  let textWithNewlines = "";
  state.doc.forEach((block) => {
    block.forEach((node) => {
      if (node.type.name === "text") {
        const linkMark = node.marks.find((mark) => mark.type.name === "link");
        textWithNewlines += linkMark ? `<${linkMark.attrs.href}>` : node.text;
      }
      if (node.type.name === "hardBreak") {
        textWithNewlines += "\n";
      }
      if (node.type.name === "mention") {
        const mentionId = node.attrs.id;
        textWithNewlines += `\<@${mentionId}\>`;
      }
      if (node.type.name === "tag") {
        const mentionId = node.attrs.id;
        textWithNewlines += `<#${mentionId}>`;
      }
    });
  });

  return textWithNewlines;
};

const USERS_SEARCH_RESULT_LIMIT = 5;
const CHANNELS_SEARCH_RESULT_LIMIT = 5;

const BasicEditor = ({
  onChange,
  placeholder,
  onEnter,
  value = "",
  extendedPlugins = [],
  canCreateTags = true,
  includedMentions = ["channel", "user", "tag"],
}: BasicEditorProps) => {
  const sessionUserId = useAppStore((state) => state.session?.user?.id);
  const tags = useAppStore((state) => state.tags);

  const { getEditorStateFromChatText } = useParser();
  const content = useMemo(() => {
    if (typeof value === "string") {
      return getEditorStateFromChatText(value);
    }
    return value;
  }, [value]);

  const editor = useEditor(
    {
      content,
      editorProps: {
        handlePaste: (view, event) => {
          const { clipboardData } = event;

          if (!clipboardData) {
            return false;
          }

          const pastedText = clipboardData.getData("text/plain");
          const containsNewLines = pastedText.includes("\n");

          if (containsNewLines) {
            event.preventDefault();

            const createTextFragment = (textPart: string) => {
              if (!textPart) {
                return [];
              }

              const textNode = view.state.schema.text(textPart);
              const breakNode = view.state.schema.node("hardBreak");

              return [textNode, breakNode];
            };

            const textFragments = pastedText
              .split("\n")
              .flatMap(createTextFragment);

            const endsWithHardBreak =
              textFragments.length &&
              textFragments[textFragments.length - 1].type.name === "hardBreak";

            if (endsWithHardBreak) {
              textFragments.pop();
            }

            const hasFragmentsToInsert = textFragments.length > 0;
            if (hasFragmentsToInsert) {
              const transaction = view.state.tr.insert(
                view.state.selection.from,
                textFragments,
              );
              view.dispatch(transaction);
              return true;
            }
          }

          return false;
        },

        attributes: { class: "editor" },
        handleKeyDown: (props, event) => {
          const mentionIsActive = props.state.plugins.some(
            (plugin) =>
              // @ts-ignore
              plugin.key.startsWith("mention$") &&
              plugin.getState(props.state).active,
          );

          if (mentionIsActive) {
            return;
          }

          if (event.key === "Enter") {
            if (event.shiftKey) {
              return;
            }
            const editorOutput = extractTextWithNewlines(props.state);
            if (!editorOutput.trim()) {
              return true;
            }
            onEnter && onEnter(editorOutput);
            return true;
          }
        },
      },
      autofocus: true,
      extensions: [
        ...(extendedPlugins.includes(ExtendedPlugins.Link)
          ? [Link.configure({ openOnClick: false })]
          : []),
        Placeholder.configure({
          placeholder,
          includeChildren: true,
        }),
        Document,
        Paragraph.configure({
          HTMLAttributes: { class: "paragraph" },
        }),
        Text,
        HardBreak,
        History,
        ...(includedMentions.includes("channel") ||
        includedMentions.includes("user")
          ? [
              Mention.extend({
                name: "mention",
              }).configure({
                HTMLAttributes: { class: "mentionNode" },
                suggestion: {
                  char: "@",
                  pluginKey: new PluginKey("mention"),
                  allowSpaces: false,
                  allowedPrefixes: ["-", " "],
                  items: ({ query }) => {
                    return new Promise(async (resolve) => {
                      const authenticatedUserId = sessionUserId;
                      if (!authenticatedUserId) return resolve([]);

                      const mentions = await getMentions(query);

                      const users: StructUser[] =
                        mentions?.users?.slice(0, USERS_SEARCH_RESULT_LIMIT) ??
                        [];
                      const channels: StructChannel[] =
                        mentions?.channels?.slice(
                          0,
                          CHANNELS_SEARCH_RESULT_LIMIT,
                        ) ?? [];
                      includedMentions.includes;

                      resolve([
                        ...(includedMentions.includes("channel")
                          ? channels.map((c) => ({ ...c, type: "channel" }))
                          : []),
                        ...(includedMentions.includes("user")
                          ? users.map((u) => ({ ...u, type: "user" }))
                          : []),
                      ]);
                    });
                  },
                  render: () => {
                    let reactRenderer: ReactRenderer | null;

                    return {
                      onStart: (props) => {
                        reactRenderer = new ReactRenderer(MentionList, {
                          props: {
                            ...props,
                            type: "mention",
                            isChatBoxEditor: false,
                          },
                          editor: props.editor,
                        });
                      },

                      onUpdate(props) {
                        reactRenderer?.updateProps(props);
                      },

                      onKeyDown(props) {
                        if (props.event.key === "Escape") {
                          reactRenderer?.destroy();
                          return true;
                        }

                        return (reactRenderer?.ref as any)?.onKeyDown(props);
                      },

                      onExit() {
                        reactRenderer?.destroy();
                        reactRenderer = null;
                      },
                    };
                  },
                },
              }),
            ]
          : []),
        ...(includedMentions.includes("tag")
          ? [
              Mention.extend({
                name: "tag",
              }).configure({
                HTMLAttributes: { class: "tagNode" },
                suggestion: {
                  char: "#",
                  pluginKey: new PluginKey("mention"),
                  allowSpaces: false,
                  allowedPrefixes: ["-", " "],
                  items: async ({ query }) => {
                    const filteredTags = tags
                      .filter((tag) =>
                        tag.tag.toLowerCase().startsWith(query.toLowerCase()),
                      )
                      .slice(0, 5);

                    return filteredTags.map((tag) => ({ ...tag, type: "tag" }));
                  },
                  render: () => {
                    let reactRenderer: ReactRenderer | null;

                    return {
                      onStart: (props) => {
                        reactRenderer = new ReactRenderer(MentionList, {
                          props: {
                            ...props,
                            type: "tag",
                            canCreateTags,
                            isChatBoxEditor: false,
                          },
                          editor: props.editor,
                        });
                      },

                      onUpdate(props) {
                        reactRenderer?.updateProps(props);
                      },

                      onKeyDown(props) {
                        if (props.event.key === "Escape") {
                          reactRenderer?.destroy();
                          reactRenderer = null;
                          return true;
                        }

                        return (reactRenderer?.ref as any)?.onKeyDown(props);
                      },

                      onExit() {
                        reactRenderer?.destroy();
                        reactRenderer = null;
                        return true;
                      },
                    };
                  },
                },
              }),
            ]
          : []),
      ],
    },
    [],
  );

  useEffect(() => {
    if (editor) {
      editor.commands.focus("end");
      editor.on("focus", () => {
        editor.commands.focus("end");
      });
    }
  }, [editor]);

  useEffect(() => {
    if (editor) {
      editor.on("update", () => {
        const newContent = extractTextWithNewlines(editor.state);
        onChange(newContent);
      });
    }

    return () => {
      if (editor) {
        editor.off("update");
        editor.off("focus");
        editor.off("blur");
      }
    };
  }, [editor, onChange]);

  useEffect(() => {
    if (!value) {
      editor?.commands.clearContent();
    }
  }, [value]);

  if (!editor) {
    return <div className={styles.basicEditorSkeleton}></div>;
  }

  return <EditorContent editor={editor} className={styles.basicEditor} />;
};

export default BasicEditor;

BasicEditor.displayName = "BasicEditor";
