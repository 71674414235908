import { useCallback } from "react";
import { FeedThreadResponse } from "@/app/types/Thread.type";
import {
  getFeedAPI,
  getFeedsAPI,
  postFeedAPI,
  putFeedAPI,
} from "@/app/services/feeds";
import { putFeedReadAPI } from "../services/chats";
import { FilterOptions, searchFeedAPI } from "@/app/services/threads";
import { SortingOption } from "@/app/types/Sorting.type";
import { globalStore, useAppStore } from "../store";
import {
  CreateFeedPayload,
  StructFeed,
  UpdateFeedPayload,
} from "@/app/types/Feed.type";
import { DEFAULT_FEED_ID, DEFAULT_SEARCH_FEED_ID } from "../store/App.store";

const useFeeds = () => {
  const organisation = useAppStore((state) => state.organisation);
  const isUnreadFeedFilterActive = useAppStore(
    (state) => state.isUnreadFeedFilterActive,
  );

  const feedTimeRange = useAppStore((state) => state.feedTimeRange);

  const currentFeedId = useAppStore((state) => state.currentFeedId);

  const setThreadCountByFeedId = useAppStore(
    (state) => state.setThreadCountByFeedId,
  );

  const setFormattedSearchQuery = useAppStore(
    (state) => state.setFormattedSearchQuery,
  );

  const organisationId = organisation?.id;

  const fetchFeed = useCallback(
    ({
      page,
      per_page,
      feedId,
    }: {
      page?: number;
      per_page?: number;
      feedId?: string;
    }): Promise<FeedThreadResponse[] | any> =>
      getFeedAPI({
        page,
        per_page,
        feedId,
        isUnread: globalStore.getState().isUnreadFeedFilterActive,
        from: feedTimeRange ? Date.now() - feedTimeRange : undefined,
        to: feedTimeRange ? Date.now() : undefined,
      })
        .then((response) => {
          setThreadCountByFeedId(
            feedId || DEFAULT_FEED_ID,
            response.num_threads,
          );
          return response.data || [];
        })
        .catch((error) => {
          console.log(error);
        }),
    [organisationId, isUnreadFeedFilterActive, feedTimeRange],
  );

  const fetchFeeds = useCallback(
    (): Promise<StructFeed[] | any> =>
      getFeedsAPI().catch((error) => {
        console.log(error);
      }),
    [organisationId],
  );

  const searchFeed = useCallback(
    ({
      q,
      page,
      filterBy,
      sortBy,
      from,
      to,
    }: {
      page?: number;
      q?: string;
      filterBy?: FilterOptions;
      sortBy?: SortingOption;
      from?: number;
      to?: number;
    }): Promise<FeedThreadResponse[] | any> =>
      searchFeedAPI({
        q,
        page,
        filterBy,
        sortBy,
        from,
        to,
      })
        .then((response) => {
          setThreadCountByFeedId(
            DEFAULT_SEARCH_FEED_ID,
            response.num_threads ?? 100,
          );
          if (response.q_plain) {
            setFormattedSearchQuery(response.q_plain);
          }

          return response.data || [];
        })
        .catch((error) => {
          console.log(error);
        }),
    [organisationId],
  );

  const createFeed = useCallback(
    (payload: CreateFeedPayload): Promise<any> =>
      postFeedAPI({
        payload,
      }).catch((error) => {
        console.log(error);
      }),
    [organisationId],
  );

  const updateFeed = useCallback(
    (payload: UpdateFeedPayload): Promise<any> =>
      putFeedAPI({
        payload,
      }).catch((error) => {
        console.log(error);
      }),
    [organisationId],
  );

  const deleteFeed = useCallback(
    ({ id }: { id: StructFeed["id"] }): Promise<any> =>
      putFeedAPI({
        payload: {
          id,
          bits: {
            deleted: true,
          },
        },
      }).catch((error) => {
        console.log(error);
      }),
    [organisationId],
  );

  const markFeedAsRead = useCallback(
    (): Promise<any> =>
      putFeedReadAPI({ feedId: currentFeedId }).catch((error) => {
        console.log(error);
      }),
    [organisationId, currentFeedId],
  );

  return {
    fetchFeed,
    fetchFeeds,
    searchFeed,
    createFeed,
    updateFeed,
    deleteFeed,
    markFeedAsRead,
  };
};

export default useFeeds;
