import { useState, useEffect } from "react";
import { isWindow } from "../utils/helper";

const DESKTOP_WIDTH = 768;

const useDesktopMode = () => {
  const [width, setWidth] = useState<number>(
    isWindow ? window.innerWidth : DESKTOP_WIDTH,
  );

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  return { isDesktop: width >= DESKTOP_WIDTH };
};

export default useDesktopMode;
