import React, { useRef } from "react";
import { debounce } from "lodash";

interface Props {
  (func: any): any;
}

const useDebounce = (func: Props, time = 1000) => {
  const debouncedFunc = useRef(debounce(func, time)).current;

  return debouncedFunc;
};

export default useDebounce;
