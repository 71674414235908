import { structAPIFetchClient } from "@/app/services/client";
import { CreateFeedPayload, StructFeed } from "@/app/types/Feed.type";
import { StructFeedThreadResponse } from "@/app/types/Thread.type";
import { STRUCT_API_VERSION } from "@/app/constants";

const FEED_ENDPOINT = `/${STRUCT_API_VERSION}/feed`;
const FEEDS_ENDPOINT = `/${STRUCT_API_VERSION}/feeds`;

export const getFeedAPI = async ({
  page,
  per_page,
  feedId,
  isUnread,
  from,
  to,
}: {
  page?: number;
  per_page?: number;
  feedId?: string;
  isUnread?: boolean;
  from?: number;
  to?: number;
}): Promise<StructFeedThreadResponse> => {
  const queryParams = new URLSearchParams();
  if (page !== undefined) queryParams.set("page", page.toString());
  if (per_page !== undefined) queryParams.set("per_page", per_page.toString());
  if (isUnread !== undefined) queryParams.set("unread", isUnread.toString());
  if (from !== undefined)
    queryParams.set("from", Math.round(from / 1000).toString());
  if (to !== undefined) queryParams.set("to", Math.round(to / 1000).toString());

  const endpoint = feedId ? `${FEED_ENDPOINT}/${feedId}` : FEED_ENDPOINT;

  const response = await structAPIFetchClient(
    `${endpoint}?${queryParams.toString()}`,
    {
      method: "GET",
    },
  );

  if (!response.ok) {
    throw new Error("Network response was not ok");
  }

  const responseData = await response.json();
  return (responseData || { data: [] }) as StructFeedThreadResponse;
};

export const getFeedsAPI = async (): Promise<StructFeed[]> => {
  const response = await structAPIFetchClient(FEEDS_ENDPOINT, {
    method: "GET",
  });
  if (response.status === 401) {
    return [];
  }

  if (!response.ok) {
    throw new Error("Network response was not ok");
  }

  const { data } = await response.json();
  return data;
};

export const putFeedAPI = async ({
  payload,
}: {
  payload: Partial<StructFeed>;
}): Promise<any> => {
  const endpoint = `${FEED_ENDPOINT}`;
  const response = await structAPIFetchClient(endpoint, {
    method: "PUT",
    // @ts-ignore
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });

  if (!response.ok) {
    throw new Error("Network response was not ok");
  }

  const { data } = await response.json();
  return data;
};

export const postFeedAPI = async ({
  payload,
}: {
  payload: CreateFeedPayload;
}): Promise<any> => {
  const endpoint = `${FEED_ENDPOINT}`;
  const response = await structAPIFetchClient(endpoint, {
    method: "POST",
    // @ts-ignore
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });

  if (!response.ok) {
    throw new Error("Network response was not ok");
  }

  const { data } = await response.json();
  return data;
};
