import { useAppStore } from "../store";
import { SortingOption } from "../types/Sorting.type";

export const useAllFilters = () => {
  const channelFilters = useAppStore((state) => state.filters.channels);
  const tagFilters = useAppStore((state) => state.filters.tags);
  const userFilters = useAppStore((state) => state.filters.users);
  const searchQuery = useAppStore((state) => state.searchQuery);
  const sorting = useAppStore((state) => state.sorting);
  const clearFilters = useAppStore((state) => state.clearFilters);
  const clearSearchQuery = useAppStore((state) => state.clearSearchQuery);
  const clearSorting = useAppStore((state) => state.clearSorting);
  const isSortingOptionDefault = sorting === SortingOption.Relevance;

  const removeAllFilters = () => {
    clearFilters();
    clearSearchQuery();
    clearSorting();
  };

  return {
    areFiltersApplied:
      Boolean(searchQuery) ||
      !isSortingOptionDefault ||
      channelFilters?.length > 0 ||
      tagFilters?.length > 0 ||
      userFilters?.length > 0,

    removeAllFilters,
  };
};
