const defaultState = {
  feedMode: 0,
  isNotificationEnabled: true,
};

export const getLocalStorageState = () => {
  const state =
    typeof window !== "undefined"
      ? window.localStorage?.getItem("struct-store") ??
        JSON.stringify(defaultState)
      : "{}";
  return JSON.parse(state);
};

// TODO: type the state
export const setLocalStorageState = (updatedState: any) => {
  const state = getLocalStorageState();
  const newState = { ...state, ...updatedState };
  typeof window !== "undefined" &&
    window.localStorage.setItem("struct-store", JSON.stringify(newState));
};
