import { IconType } from "@/app/components/icons/icon.type";

export const CrossSmallIcon = ({ style, className }: IconType) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
    className={className}
  >
    <rect width="16" height="16" fill="transparent" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.1528 5.7145C11.3924 5.47498 11.3924 5.08664 11.1528 4.84712C10.9134 4.6076 10.525 4.6076 10.2855 4.84712L8.00006 7.13255L5.71463 4.84712C5.4751 4.6076 5.08676 4.6076 4.84724 4.84712C4.60772 5.08664 4.60772 5.47498 4.84724 5.7145L7.13268 7.99994L4.84724 10.2854C4.60772 10.5249 4.60772 10.9132 4.84724 11.1528C5.08676 11.3922 5.4751 11.3922 5.71463 11.1528L8.00006 8.86732L10.2855 11.1528C10.525 11.3922 10.9134 11.3922 11.1528 11.1528C11.3924 10.9132 11.3924 10.5249 11.1528 10.2854L8.86744 7.99994L11.1528 5.7145Z"
      fill="currentColor"
    />
  </svg>
);
