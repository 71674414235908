import React, { ReactNode } from "react";
import styles from "./Toggle.module.scss";
import { Tooltip } from "@radix-ui/themes";

type ToggleProps = {
  selectedIndex: number;
  onCheckedChange: (checked: number) => void;
  items: {
    description: string;
    icon: ReactNode;
    isDisabled?: boolean;
  }[];
};

const Toggle: React.FC<ToggleProps> = ({
  selectedIndex,
  onCheckedChange,
  items,
}) => {
  const transformPercent =
    selectedIndex === 0 ? "-1px" : `${selectedIndex * 100 - 5}%`;

  return (
    <div className={styles.toggleContainer}>
      <div
        className={styles.floatingBox}
        style={{
          transform: `translateX(${transformPercent})`,
          width: `${100 / items.length - 1}%`,
        }}
      />
      {items.map(({ icon, description, isDisabled = false }, index) => (
        <Tooltip key={index} content={description} delayDuration={0}>
          <span
            onClick={() => !isDisabled && onCheckedChange(index)}
            style={{
              cursor: isDisabled ? "not-allowed" : "pointer",
              opacity: isDisabled ? 0.5 : 1,
            }}
            className={`${styles.icon} ${
              selectedIndex === index ? styles.active : ""
            }`}
          >
            {icon}
          </span>
        </Tooltip>
      ))}
    </div>
  );
};

export default Toggle;
